import {
  Checkbox,
  CommandBar,
  ContextualMenu,
  ContextualMenuItem,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IButtonProps,
  ICommandBarItemProps,
  IContextualMenuItemProps,
  Link,
  PrimaryButton
} from "@fluentui/react";
import {
  MgtPerson,
  PeoplePicker,
  Person,
  PersonCardInteraction,
  PersonType,
  UserType,
  ViewType,
} from "@microsoft/mgt-react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import React, { useState } from "react";
import { directoryObject } from "../objects/directoryObj";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import { apiScopes } from "../authConfig";
import { getUserByEmail } from "../services/graph";
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";

interface OwnerManagerProps {
  currentOwners: string[];
  resourceId: string;
  addFunction: (ownerList:directoryObject[], resourceId: string) => void;
  removeFunction: (ownerList:directoryObject[], resourceId: string) => void;
  isM365: boolean;
  isAGS: boolean;
}

const CustomMenuItem: React.FunctionComponent<IContextualMenuItemProps> = (props) => {
  return <ContextualMenuItem {...props} />;
};

const dialogStyles = { main: { maxWidth: 450 } };
const dragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  keepInBounds: true,
};

export function OwnerManager(props: OwnerManagerProps) {
  const { instance, accounts } = useMsal();
  MgtPerson.config.useContactApis = true;
  let selectedOwners: Array<directoryObject> = [];
  const [hidePickerErrorSummary, setHidePickerErrorSummary] = useState(true);
  const [checkedOwners, setCheckedOwners] = useState<string[]>([]);
  const [errorSummaryText, setErrorSummaryText] = useState("");
  const [hideOwnerSelectionDialog, { toggle: toggleOwnerSelectionHideDialog }] = useBoolean(true);
  const [isDraggable] = useBoolean(false);
  const labelId: string = useId("dialogLabel");
  const subTextId: string = useId("subTextLabel");
  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
      dragOptions: isDraggable ? dragOptions : undefined,
    }),
    [isDraggable, labelId, subTextId]
  );
  const ownerDialogContentProps = {
    type: DialogType.normal,
    title: `Add ${props.isM365 ? "owners" : "site collection administrators"}`,
    closeButtonAriaLabel: "Close",
    subText: `Manage ${props.isM365 ? "owners" : "site administrators"} for this workspace`,
  };
  function handleOwnerAddDialog(){
    setHidePickerErrorSummary(true);
    setErrorSummaryText("");
    toggleOwnerSelectionHideDialog();
  }
  function renderOwnerRemovalWarning(ownerList: string[]): JSX.Element {
    return (
      <div>
        <h5>Remove the selected owner(s)?</h5>
        <div
          style={{
            display: ownerList.includes((window as any).CURRENT_USER_EMAIL) ? "block" : "none",
          }}
        >
          <br></br>
          <div className="alert alert-warning" role="alert">
            <strong>Warning: </strong> You are attempting to remove yourself; you will no longer be
            able to manage this workspace.
          </div>
        </div>
      </div>
    );
  }
  function handleOwnerCheckColumn(ownerId: string, isChecked: boolean) {
    if (isChecked) {
      if (checkedOwners.includes(ownerId) === false) {
        setCheckedOwners([...checkedOwners, ownerId]);
      }
    } else {
      setCheckedOwners(
        checkedOwners.filter((email) => {
          return email !== ownerId;
        })
      );
    }
  }

  function handleOwnerRemovalDialog() {
    let selectedOwners: directoryObject[] = [];
    if (checkedOwners.length > 0) {
      let ownersAfterRemoval = props.currentOwners.length - checkedOwners.length;
      if (props.currentOwners.length > 2 && ownersAfterRemoval >= 2) {
        Swal.fire({
          title: "Two owner compliance",
          html: renderToStaticMarkup(renderOwnerRemovalWarning(checkedOwners)),
          showCancelButton: true,
          confirmButtonColor: "#CE0000",
          confirmButtonText: "Remove",
        }).then((result) => {
          if (result.isConfirmed) {
            const accessTokenRequest = {
              scopes: apiScopes.graphScopes,
              account: accounts[0],
            };
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
              // Acquire token silent success
              let accessToken = accessTokenResponse.accessToken;
              // Map over the array and call the async function for each element
              const promises = checkedOwners.map(async (ownerEmail) => {
                try {
                  let userIdResponse = await getUserByEmail(accessToken, ownerEmail);
                  if ((userIdResponse as any).id !== "") {
                    let currentUserObj: directoryObject = {};
                    currentUserObj.userPrincipalName = ownerEmail;
                    currentUserObj.id = (userIdResponse as any).id;
                    selectedOwners.push(currentUserObj);
                  }
                } catch {
                  console.error(`Error while retrieving ID for ${ownerEmail}`);
                }
              });
              Promise.all(promises).then(()=>{
                props.removeFunction(selectedOwners,props.resourceId);
              });
            });
          }
        });
      } else {
        Swal.fire({
          title: "Ownership Enforcement",
          text: `The workspace must have at least 2 ${props.isM365 ? "owners" : "administrators"}`,
        });
      }
    }
  }

  function searchEmail(emailParam: string): boolean {
    for (let index = 0; index < props.currentOwners.length; index++) {
      let currentOwner = props.currentOwners[index];
      if (currentOwner.trim().toLowerCase() === emailParam.trim().toLowerCase()) {
        return true;
      }
    }
    return false;
  }
  const _items: ICommandBarItemProps[] = [
    {
      key: "add",
      text: `Add ${props.isM365 ? "Owners" : "Admins"}`,
      iconProps: { iconName: "Add" },
      onClick: () => {handleOwnerAddDialog();},
      disabled: props.isAGS
    },
    {
      key: "remove",
      text: "Remove",
      iconProps: { iconName: "Cancel" },
      onClick: () => {handleOwnerRemovalDialog();},
      disabled: props.isAGS
    },
    {
      key: "refresh",
      text: "Refresh",
      iconProps: { iconName: "Refresh" },
      onClick: () => {
        Swal.showLoading();
        const randomNum: number = Math.floor(Math.random() * (5 - 2 + 1)) + 2;
        setTimeout(() => {
          Swal.close();
        }, (randomNum * 1000));
      },
    },
    {
      key: "feedback",
      text: "Feedback",
      iconProps: { iconName: "Feedback" },
      onClick: () => {
        const feedbackLinkElement = document.getElementById("btnOpenFeedbackForm");
        feedbackLinkElement?.click();
      },
    },
  ];
  const overflowProps: IButtonProps = {
    menuProps: {
      contextualMenuItemAs: CustomMenuItem,
      // Styles are passed through to menu items here

      items: [], // CommandBar will determine items rendered in overflow
      isBeakVisible: true,
      beakWidth: 20,
    },
  };
  return (
    <div>
      <Dialog
        hidden={hideOwnerSelectionDialog}
        onDismiss={toggleOwnerSelectionHideDialog}
        dialogContentProps={ownerDialogContentProps}
        modalProps={modalProps}
      >
        <div className="row">
          <div className="col">
            <div>
              <PeoplePicker
                type={PersonType.person}
                userType={UserType.user}
                className="mgt-light light-border"
                userFilters={`userType eq 'Member' and mail ne '${
                  (window as any).CURRENT_USER_EMAIL
                }'`}
                showMax={10}
                selectionMode="multiple"
                selectionChanged={(event: any) => {
                  selectedOwners = [];
                  let selectedValue: Array<directoryObject> = event[
                    "detail"
                  ] as Array<directoryObject>;
                  if (selectedValue.length > 0) {
                    selectedOwners = selectedValue;
                    setHidePickerErrorSummary(true);
                    setErrorSummaryText("");
                  } else {
                    setErrorSummaryText(
                      `No ${props.isM365 ? "owner" : "site administrator"} was selected`
                    );
                    setHidePickerErrorSummary(false);
                  }
                }}
              ></PeoplePicker>
              <div
                className="invalid-feedback"
                style={{ display: hidePickerErrorSummary ? "none" : "grid" }}
              >
                {errorSummaryText}
              </div>
            </div>
          </div>
        </div>
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              let filteredOwners: directoryObject[] = [];
              if (selectedOwners.length > 0) {
                selectedOwners.forEach((currOwner) => {
                  if (
                    searchEmail(currOwner.userPrincipalName?.trim().toLowerCase() as string) ===
                    false
                  ) {
                    filteredOwners.push(currOwner);
                  }
                });
                if (filteredOwners.length > 0) {
                  toggleOwnerSelectionHideDialog();
                  props.addFunction(filteredOwners, props.resourceId);
                } else {
                  toast.info(
                    `The selected users are already ${props.isM365 ? "owners" : "site administrators"}`,
                    {
                      type: "info",
                      isLoading: false,
                      autoClose: 8000,
                    }
                  );
                }
              }
            }}
            text="Confirm"
          />
          <DefaultButton
            onClick={() => {
              setHidePickerErrorSummary(true);
              setErrorSummaryText("");
              toggleOwnerSelectionHideDialog();
            }}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
      <div>
        <br />
        <div style={{ paddingLeft: "14px", paddingRight: "14px", display: props.isAGS ? "grid" : "none"}}>
          <div className="alert alert-warning" role="alert">
            {" "}
            <strong>⚠</strong>
            This site is managed by the AGS system, go{" "}
            <a
              href="https://ags.intel.com/identityiq/home.jsf"
              target="_blank"
              className="alert-link" rel="noopener noreferrer"
            >
              HERE
            </a>{" "}
            if you require to manage ownership of this space
          </div>
        </div>
        <div className="row">
          <div className="col">
            <br />
            <div style={{ paddingLeft: "14px", paddingRight: "14px" }}>
              <CommandBar
                items={_items}
                overflowButtonProps={overflowProps}
                ariaLabel="Inbox actions"
                primaryGroupAriaLabel="Email actions"
                farItemsGroupAriaLabel="More actions"
              />
              <hr style={{ color: "rgb(233, 233, 233)", margin: "0" }}></hr>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <div className="col-sm-3"></div>
              </div>
              <table className="table" style={{ backgroundColor: "white" }}>
                <thead>
                  <tr style={{ textAlign: "justify" }}>
                    <th></th>
                    <th>Name</th>
                    <th>User Type</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {props.currentOwners.map((owner: string, index) => {
                    return (
                      <tr key={index} style={{ textAlign: "justify" }}>
                        <td>
                          <Checkbox
                            label=""
                            disabled={props.isAGS}
                            onChange={(event, ischecked) => {
                              handleOwnerCheckColumn(owner, ischecked?.valueOf() as boolean);
                            }}
                          />
                        </td>
                        <td style={{ width: "40%" }}>
                          {owner.startsWith("ad_") ? (
                            <Person
                              fallbackDetails={{ displayName: owner.trim(), initials: "AD" }}
                              personCardInteraction={PersonCardInteraction.none}
                              avatarType="photo"
                              avatarSize="small"
                              view={ViewType.oneline}
                            ></Person>
                          ) : (
                            <Person
                              fetchImage
                              personQuery={owner.trim()}
                              person-card="hover"
                              showPresence={true}
                              personCardInteraction={PersonCardInteraction.hover}
                              avatarType="photo"
                              avatarSize="small"
                              view={ViewType.oneline}
                            ></Person>
                          )}
                        </td>
                        <td style={{ width: "15%" }}>{"Site Collection Administrator"}</td>
                        <td style={{ width: "45%" }}>
                          <Link href={owner}>{owner}</Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  );
}

import { m365AppsBaseLinks, otherValues } from "./authConfig";
import { ComplianceRule} from "./enums";
import { GraphEntry } from "./objects/m365group";
import { nonConnectedSite} from "./objects/spoSite";
import { m365group } from "./objects/m365group";
import { resource } from "./objects/resource";
import siteTemplates from "./SPOTemplates.json";
import { complianceResult } from "./objects/complianceResult";
import { TooltipHost } from "@fluentui/react";

/**
 * Returns a link to the target resource
 * @param currentResource A resource object
 * @returns A link to SharePoint, Teams or Viva Engage
 */
export function getResourceLink(currentResource: resource): string {
  if (currentResource.resourceType === "SPO" || currentResource.resourceType === "CSPO") {
    return currentResource.siteUrl;
  } else if (currentResource.resourceType === "Teams") {
    if (currentResource.teamsUrl === "") {
      return "https://teams.microsoft.com";
    }
    return currentResource.teamsUrl;
  } else if (currentResource.resourceType === "Yammer") {
    return `${m365AppsBaseLinks.yammer}/search/groups?search=${currentResource.displayName}`;
  } else {
    return currentResource.siteUrl;
  }
}

/**
 * Checks of a given Graph API group entry is a team
 * @param graphEntry A Graph API group entry result
 * @returns If the resource is a team or not
 */
export function resourceHasTeams(graphEntry: GraphEntry): boolean {
  return (graphEntry.resourceProvisioningOptions as string[]).includes("Team");
}

/**
 * Checks if the given Graph API group entry has a Yammer(Viva Engage) community
 * @param graphEntry A Graph API group entry result
 * @returns If the resource is a Yammer(Viva Engage) community or not
 */
export function resourceHasYammer(graphEntry: GraphEntry): boolean {
  return (graphEntry.creationOptions as string[]).includes("YammerProvisioning");
}

/**
 * Takes a list of m365 connected and non connected resources and tranforms them into a list of <resource> objects which
 * are the objects used by the main grid to display the details of the current user's owned resources
 * This function also ensures that the activity dates are calculated comparing multiple data sources
 * @param ownedSPOSites A list of m365 groups, teams or yammer communities
 * @param ownedM365Groups A list of non connected sites
 * @returns A list of <resource> objects to be displayed in the main grid
 */
export function createAllResourcesList(
  ownedSPOSites: Array<nonConnectedSite>,
  ownedM365Groups?: Array<m365group>
): Array<resource> {
  let resourceList: Array<resource> = [];
  for (let index = 0; index < ownedM365Groups!.length; index++) {
    let currentResource: resource = new resource();
    currentResource.azureAdId = ownedM365Groups![index].governanceEntry!.id as string;
    currentResource.displayName = ownedM365Groups![index].graphEntry?.displayName as string;
    currentResource.isGroupConnected = true;

    //Build the SharePoint site url using the tenant url and the mailnickname
    currentResource.siteUrl = `${m365AppsBaseLinks.sharepoint}/sites/${ownedM365Groups![index]
      .graphEntry?.mailNickname!}`;
    
    //Check if the resource is a team, group or yammer
    //If the resource is a team get the url so the user can navigate to it on the UI
    if (resourceHasTeams(ownedM365Groups![index].graphEntry!)) {
      currentResource.resourceType = "Teams";
      try {
        currentResource.teamsUrl = ownedM365Groups![index].groupTeam?.webUrl as string;
      } catch {
        currentResource.teamsUrl = "https://teams.microsoft.com";
      }
    } else if (resourceHasYammer(ownedM365Groups![index].graphEntry!)) {
      currentResource.resourceType = "Yammer";
    } else {
      currentResource.resourceType = "CSPO";
    }
    currentResource.classification =
      (ownedM365Groups![index].graphEntry?.classification as string) || "Not Set";
    currentResource.privacy = ownedM365Groups![index].graphEntry?.visibility as string;
    currentResource.mailbox = ownedM365Groups![index].graphEntry?.mail as string;
    currentResource.mailboxEnabled = ownedM365Groups![index].graphEntry?.mailEnabled as boolean;
    currentResource.complianceState = ownedM365Groups![index].governanceEntry!
      .complianceState as number;

    //If the renew date is new which means the resource was recently created, assigned a renew date of = today + 6 months
    if (ownedM365Groups![index].governanceEntry?.renewDateFormatted == null) {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() + 6);
      currentResource.renewDateFormatted = currentDate;
    } else {
        currentResource.renewDateFormatted = new Date (ownedM365Groups![index].governanceEntry!
          .renewDateFormatted as Date);
    }

    //Add the owners of the resource
    currentResource.owners = ownedM365Groups![index].owners?.map(
      (owner) => owner.userPrincipalName
    ) as string[];
    try {
      let currentElement = ownedM365Groups![index].graphEntry;
      let currentAgsFlagValue = (currentElement as any)[otherValues.agsAtributeName];
      if (currentAgsFlagValue === 2) {
        currentResource.agsTracked = true;
      }
    } catch {
      currentResource.agsTracked = false;
    }

    //Get the activity objects
    currentResource.groupActivity = ownedM365Groups![index].activity;
    currentResource.teamsActivity = ownedM365Groups![index].teamsActivity;
    currentResource.yammerActivity = ownedM365Groups![index].yammerActivity;

    if (ownedM365Groups![index].activity != null) {
      currentResource.membersCount = parseInt(
        ownedM365Groups![index].activity?.memberCount as string
      );
      currentResource.externalMembersCount = parseInt(
        ownedM365Groups![index].activity?.externalMemberCount as string
      );
    }
    if(ownedM365Groups![index].graphEntry?.assignedLabels != null){
      if(ownedM365Groups![index].graphEntry!.assignedLabels!.length > 0){
        currentResource.sensitivityLabel = ownedM365Groups![index].graphEntry!.assignedLabels![0].displayName as string;
      }
    }

    //Check if activity records are available for the resource
    if(ownedM365Groups![index].activity !== null || ownedM365Groups![index].teamsActivity !== null || ownedM365Groups![index].yammerActivity !== null){
      currentResource.activityDataAvailable = true;
    }

    //Call the function to calculate the last activity date
    currentResource.lastUpdatedFormatted = calculateLastActivityM365(ownedM365Groups![index]);
    currentResource.renewActivityLog = ownedM365Groups![index].renewActivityLog;
    //If the resource is candidate to autorenewall set the renewdate to 2050
    if(isWithinLast90Days(currentResource.lastUpdatedFormatted) && currentResource.owners.length > 1 && currentResource.sensitivityLabel !== "Internal - External"){
      currentResource.renewDateFormatted = new Date('2050-01-01');
    }
    resourceList.push(currentResource);
  }
  for (let index = 0; index < ownedSPOSites!.length; index++) {
    let currentResource: resource = new resource();
    currentResource.siteUrl = ownedSPOSites![index].sharePointSite?.siteUrl as string;
    currentResource.displayName = ownedSPOSites![index].sharePointSite?.name as string;
    currentResource.isGroupConnected = false;
    currentResource.resourceType = "SPO";
    currentResource.classification =
      ownedSPOSites![index].sharePointSite?.classification || "Not Set";
    currentResource.privacy = "";
    currentResource.mailboxEnabled = false;
    currentResource.mailbox = "";
    currentResource.spoTemplateCode = ownedSPOSites![index].sharePointSite?.template!;
    currentResource.complianceState = ownedSPOSites![index].sharePointSite?.complianceState!;

    //If the renew date is new which means the resource was recently created, assigned a renew date of = today + 6 months
    if (ownedSPOSites![index].sharePointSite?.renewDateFormatted == null) {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() + 6);
      currentResource.renewDateFormatted = currentDate;
    } else {
        currentResource.renewDateFormatted = new Date(ownedSPOSites![index].sharePointSite
          ?.renewDateFormatted as Date);
    }

    //Add the owners of the resource
    if (ownedSPOSites![index].sharePointSite?.ownersDetail !== "") {
      currentResource.owners = ownedSPOSites![index].sharePointSite?.ownersDetail?.split(
        " "
      ) as Array<string>;
    } else {
      currentResource.owners = [];
    }
    let matchingTemplate = siteTemplates.find(
      (x) => x.name === ownedSPOSites![index].sharePointSite?.template
    );

    //Get the template information of the site
    if (matchingTemplate) {
      currentResource.siteTemplate.id = matchingTemplate.id;
      currentResource.siteTemplate.name = matchingTemplate.name;
      currentResource.siteTemplate.title = matchingTemplate.title;
      currentResource.siteTemplate.description = matchingTemplate.description;
    }
    currentResource.membersCount = ownedSPOSites![index].sharePointSite?.membersCount as number;
    currentResource.externalMembersCount = ownedSPOSites![index].sharePointSite
      ?.externalMembersCount as number;
    if (ownedSPOSites![index].sharePointActivity !== null){
      currentResource.siteActivity = ownedSPOSites![index].sharePointActivity;
    }
    currentResource.sensitivityLabel = ownedSPOSites![index].sharePointSite!.sensitivityLabel as string;
    currentResource.siteId =ownedSPOSites![index].sharePointSite?.id as string;

    //Check if activity records are available for the resource
    if(ownedSPOSites![index].sharePointActivity !== null){
      currentResource.activityDataAvailable = true;
    }

    //Call the function to calculate the last activity date
    currentResource.lastUpdatedFormatted = calculateLastActivitySPO(ownedSPOSites![index]);
    currentResource.renewActivityLog = ownedSPOSites![index].renewActivityLog;
    //If the resource is candidate to autorenewall set the renewdate to 2050
    if(isWithinLast90Days(currentResource.lastUpdatedFormatted) && currentResource.owners.length > 1 && currentResource.sensitivityLabel !== "Internal - External"){
      currentResource.renewDateFormatted = new Date('2050-01-01');
    }
    resourceList.push(currentResource);
  }
  return resourceList;
}

/**
 *  Converts a date to a readable format
 *  @param {Date} targetDate Date to convert
 *  @param {Boolean} showWeekDay To include weekday or not
 *  @returns {Boolean} Date in locale string format
 */
export function getFormattedDate(targetDate: Date, showWeekDay: Boolean): string {
  try {
    let dateObj = new Date(targetDate);
    return showWeekDay
      ? dateObj.toLocaleDateString("en-us", {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric",
        })
      : dateObj.toLocaleDateString("en-us", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
  } catch {
    return targetDate.toLocaleDateString();
  }
}

/**
 * Converts bytes to MB
 * @param stringToConvert A big number in string format 
 * @returns The converted value in MB
 */
export function ConvertBytesToMB(stringToConvert: string): number {
  try {
    if (stringToConvert !== "") {
      let numberValue: number = parseInt(stringToConvert);
      let resultMB = Math.round(numberValue / 1024 ** 2);
      return resultMB;
    }
    return 0;
  } catch {
    return 0;
  }
}

/**
 * Converts a date into a more readable format
 * @param dateConvert A string containing a valid date
 * @returns A long date string
 */
export function ConvertDateStringToLongDate(dateConvert: string | undefined): string {
try{
  const date = new Date(dateConvert as string);
  if (isNaN(date.getTime())) {
    return dateConvert as string;
  }

  const formattedDate = date.toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return formattedDate;
}
catch{
  return dateConvert ? dateConvert : "Not Available";
}
}

/**
 * Checks if a resource is a compliant state, returns an array containing the compliance state of reach rule for the given resource
 * @param resource A <resource> object
 * @returns An array of compliance results
 */
export function checkResourceCompliance(resource: resource): Array<complianceResult> {
  let currentDate: Date = new Date();
  let calcResultList: Array<complianceResult> = [];
  var resourceRenewDate: Date = new Date(resource.renewDateFormatted);
  let timeDifference: number = resourceRenewDate.getTime() - currentDate.getTime();

  //Rule 1
  //2 Owner enforcement
  let ownerCount: number = resource.owners.length;
  if (ownerCount < 2) {
    let complianceResult = {
      isCompliant: false,
      isExpired: false,
      remainingLeaseDays: 0,
      complianceRule: ComplianceRule.TwoOwnerEnforcement,
      complianceMessage: "Your workspace has less than 2 owners.",
    };
    calcResultList.push(complianceResult);
  } else {
    let complianceResult = {
      isCompliant: true,
      isExpired: false,
      remainingLeaseDays: 0,
      complianceRule: ComplianceRule.TwoOwnerEnforcement,
      complianceMessage: "Your workspace has the minimun required amount of owners",
    };
    calcResultList.push(complianceResult);
  }
  //Rule 2
  //Lease Expiration
  let daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
  if (daysDifference < 0) {
    let complianceResult = {
      isCompliant: true,
      isExpired: true,
      remainingLeaseDays: daysDifference,
      complianceRule: ComplianceRule.LeaseExpiration,
      complianceMessage: `The lease on your resource expired ${Math.abs(daysDifference)} days ago`,
    };
    calcResultList.push(complianceResult);
  } else {
    let complianceResult = {
      isCompliant: true,
      isExpired: false,
      remainingLeaseDays: daysDifference,
      complianceRule: ComplianceRule.LeaseExpiration,
      complianceMessage: `Your resource expires in ${Math.abs(daysDifference)} days`,
    };
    calcResultList.push(complianceResult);
  }

  return calcResultList;
}

/**
 * Renders a renew date object which depending of how long ago it expired can be displayed in a different color
 * @param complianceResults A set of compliance results
 * @param formatedComplianceDate A formatted compliance date
 * @returns A renew date tag
 */
export function renderRenewDate(
  complianceResults: Array<complianceResult>,
  formatedComplianceDate: string
): JSX.Element {
  let tooltipText: string = "";
  //Default Style: Success
  let dateColor: string = "#FFFFFF";
  let backgroundColor: string = "#008A00";
  let iconClass: string = "intelicon-protection-checked-verified-outlined";

  (complianceResults as Array<complianceResult>).forEach((compResult) => {
    if (compResult.complianceRule === ComplianceRule.LeaseExpiration) {
      let dateDifference: number = compResult.remainingLeaseDays;
      tooltipText = compResult.complianceMessage;
      if (dateDifference < 0) {

        //If already expired use red color (error)
        dateColor = "#FFFFFF";
        backgroundColor = "#CE0000";
        iconClass = "intelicon-close-solid";
      } else if (dateDifference > 0 && dateDifference <= 7) {
        
        //If remaining lease days from 0 - 7 use red color (error)
        dateColor = "#FFFFFF";
        backgroundColor = "#CE0000";
        iconClass= "intelicon-close-solid";
      } else if (dateDifference > 7 && dateDifference <= 90) {

        //If remaining lease days from 8 - 89 use orange color (warning)
        dateColor = "#525252";
        backgroundColor = "#FFD100";
        iconClass = "intelicon-information-solid";
      } else if (dateDifference > 90) {

        //If remaining lease days 90+ use green color (success)
        dateColor = "#FFFFFF";
        backgroundColor = "#008A00";
        iconClass = "intelicon-protection-checked-verified-outlined";
        formatedComplianceDate = "Active";
      }
    }
  });
  return (
    <div style={{marginTop:"5px",width:"100%"}}>
      <TooltipHost content={tooltipText} style={{width:"100%"}}>
      <div style={{color: dateColor, fontWeight: "bolder",backgroundColor: backgroundColor ,padding:"3px", marginTop:"5px",width:"100%"}}><i className={iconClass}></i> {formatedComplianceDate}</div>
      </TooltipHost>
    </div>
  );
}

/**
 * Renders a compliance tooltip
 * @param isExpired If the resource is expired or not
 * @param isCompliant If the resource is compliant or not
 * @returns A compliance banner with a custom message
 */
export function renderComplianceTooltip(isExpired: boolean, isCompliant: boolean): JSX.Element {
  if (isExpired) {
    return (
      <span style={{ color: "#B24501", fontWeight: "bolder" }}>
        Your resource is expired, please renew the lease
      </span>
    );
  } else if (isCompliant) {
    return (
      <span style={{ color: "#00377C", fontWeight: "bolder" }}>
        Your resource is compliant, no further action is required
      </span>
    );
  } else {
    return (
      <span style={{ color: "#B24501", fontWeight: "bolder" }}>
        Your resource is not compliant, please correct the following issues:
      </span>
    );
  }
}

/**
 * Removes the @ domain segment from a email string
 * @param email An email in string format
 * @returns The email without the domain section
 */
export function removeDomainFromMail(email: string): string {
  // Find the index of '@' symbol
  const atIndex: number = email.indexOf('@');

  // Check if '@' symbol is found
  if (atIndex !== -1) {
    // Extract the substring before '@' symbol
    const username: string = email.substring(0, atIndex);

    return username;
  }

  // Return the original email if no '@' symbol is found
  return email;
}

/**
 * Generates a random string from 1-20 characters
 * @param x Number of characters
 * @returns A random string with X characters
 */
export function generateRandomString(x: number): string {
  const allowedCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let randomString = "";

  if (x < 1 || x > 20) {
    throw new Error("Input number must be between 1 and 20");
  }

  for (let i = 0; i < x; i++) {
    const randomIndex = Math.floor(Math.random() * allowedCharacters.length);
    randomString += allowedCharacters[randomIndex];
  }

  return randomString;
}

/**
 * Takes a canvas element and draws a string inside it
 * @param elementID An HTML element ID
 * @param message A custom message
 */
export function drawTextInCanvas(elementID:string, message:string): void {
  const canvas = document.getElementById(elementID) as HTMLCanvasElement;
  if (!canvas) {
    throw new Error("Canvas element not found");
  }

  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("2D rendering context not supported");
  }

  ctx.font = "48px serif";

  // Calculate X coordinate for horizontal centering
  const textMetrics = ctx.measureText(message);
  const textWidth = textMetrics.width;
  const textHeight = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;

  // Calculate X coordinate for horizontal centering
  const x = (canvas.width - textWidth) / 2;

  // Calculate Y coordinate for vertical centering
  const y = (canvas.height - textHeight) / 2 + textMetrics.actualBoundingBoxAscent;

  ctx.strokeText(message, x, y);

}

/**
 * Calculates if lease extension should be disabled depending of the number of days and  if the resource is compliant with the two owner rule
 * @param isTwoOwnerEnforcementCompliant If the resource is compliant with the two owner rule
 * @param remainingLeaseDays How many days of available lease the resource lease
 * @returns If the resource lease can be extended or not
 */
export function shouldDisableLaseExtend(
  isTwoOwnerEnforcementCompliant: Boolean,
  remainingLeaseDays: number
) {
  if (isTwoOwnerEnforcementCompliant === false) {
    return true;
  }
  if (remainingLeaseDays > 180) {
    return true;
  }
}

/**
 * Calculate elapsed time from x to the current browser datetime and present it a a readable way
 * @param date A date object
 * @returns The difference between x and the current systemdate in string format
 */
export function calculateTimePassed(date: Date): string {
  try{
    const now = new Date();
    const then = new Date(date);
    const diffInMs = now.getTime() - then.getTime();
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    
    if (diffInHours < 24) {
        if (diffInHours === 0) {
            const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
            return `${diffInMinutes} minutes ago`;
        }
        return `${diffInHours} hours ago`;
    } else {
        const diffInDays = Math.floor(diffInHours / 24);
        return `${diffInDays} days ago`;
    }
  }
  catch{
    return "Some time ago"
  }
}

/**
 * Calculate the last activity date of a given resource using the following sources:
 * 1: Resource creation date
 * 2: Group activity report
 * 3: Teams Activiy
 * 4: Viva Engage activity
 * @param m365group A connected m365 group
 * @returns The last activity date of the resource
 */
export function calculateLastActivityM365(m365group: m365group): Date {
  try{
    let dateList: Date[] = [];
    dateList.push(new Date(m365group.governanceEntry!.creationDateFormatted!));
    if (m365group.activity !== null) {
      dateList.push(new Date(m365group.activity!.lastActivityDateFormatted!));
    }
    if (m365group.teamsActivity !== null) {
      dateList.push(new Date(m365group.teamsActivity!.lastActivityDateFormatted!));
    }
    if (m365group.yammerActivity !== null) {
      dateList.push(new Date(m365group.yammerActivity!.lastActivityDateFormatted!));
    }
    dateList.sort((a, b) => b.getTime() - a.getTime());
    return dateList[0];
  }
  catch {
    console.warn('failed to calculate last activity for group');
    return new Date('1900-01-01');
  }
}

/**
 * Calculate the last activity date of a given non connected site using the following sources:
 * 1: Last content modified date
 * 2: SharePoint activiy report
 * @param nonConnectedSite A non connected SPO site
 * @returns The last activity date of the resource
 */
export function calculateLastActivitySPO(nonConnectedSite: nonConnectedSite): Date {
  try{
    let dateList: Date[] = [];
    dateList.push(new Date('1900-01-01'));
    if (nonConnectedSite.sharePointActivity !== null) {
      dateList.push(new Date(nonConnectedSite.sharePointActivity?.lastActivityDateFormatted!));
    }
    dateList.sort((a, b) => b.getTime() - a.getTime());
    return dateList[0];
  }
  catch{
    console.warn('failed to calculate last activity for spo site');
    return new Date('1900-01-01');
  }
}

/**
 * Returns true is the given date is whithin the past 30 days
 * @param date A Date value
 * @returns If the date is between today and 30 days ago
 */
export function isWithinLast90Days(date: Date): boolean {
  try {
      const now = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(now.getDate() - 90);

      if (date >= thirtyDaysAgo && date <= now) {
          return true;
      } else {
          return false;
      }
  } catch (error) {
      console.error('Error occurred:', error);
      return false;
  }
}